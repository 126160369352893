<template>
  <div>
    <div :id="chartsName" style="width:4rem;height:3rem"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
// import bus from '@/utils/bus.js'
export default {
  props: {
    chartsName: {
      type: String,
    },
    // total:{
    //   type:Number,
    //   default:0
    // },
    value: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      // flowbalanceTitle:this.$t('lang.Flowbalance'),
      // storagebalanceTitle:this.$t('lang.Storagebalance'),
      // UsedTitle:this.$t('lang.Used'),
      // RemainingstoragespaceTitle:this.$t('lang.Remainingstoragespace'),
      // ResidualflowTitle:this.$t('lang.Residualflow')
    };
  },
  watch: {
    value: {
      handler(newVal, oldVal) {
         this.initCharts();
      },
      immediate: true,
    },
  },
  created() {
    // bus.$on('langchange', () => {
    //   this.flowbalanceTitle = this.$t('lang.Flowbalance');
    //   this.storagebalanceTitle = this.$t('lang.Storagebalance');
    //   this.UsedTitle = this.$t('lang.Used'),
    //   this.RemainingstoragespaceTitle = this.$t('lang.Remainingstoragespace'),
    //   this.ResidualflowTitle = this.$t('lang.Residualflow')
    // })
  },
  mounted() {
    setTimeout(() => {
      this.initCharts();
    },1000)
  },
  methods: {
    initCharts() {
      let myPieChart = echarts.init(document.getElementById(this.chartsName));
      let chartsVal = this.value * 100;
	  let sr=Math.round(chartsVal)
      console.log(chartsVal)
      // let usedVal = this.value * 100;
      // let chartsColor = this.chartsFlag ?  "#E75A48" : "#FFC450" ;
      // let title = this.chartsFlag ? this.flowbalanceTitle : this.storagebalanceTitle;
      // let unit = this.chartsFlag ? "M" : "G";
      // let UsedTitle = this.UsedTitle;
      // let leftTitle = this.chartsFlag ? this.ResidualflowTitle : this.RemainingstoragespaceTitle;
      myPieChart.setOption({
        title: [
          {
            text: `${this.$t("lang.Used")}${sr}%`,
            x: "center",
            top: "46%",
            textStyle: {
              fontSize: "14",
              color: "#10c55b",
              fontFamily: "DINAlternate-Bold, DINAlternate",
              foontWeight: "600",
            },
          },
        ],
        backgroundColor: "#fff",
        polar: {
          radius: ["42%", "52%"],
          center: ["50%", "50%"],
        },
        angleAxis: {
          max: 100,
          show: false,
        },
        radiusAxis: {
          type: "category",
          show: true,
          axisLabel: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        series: [
          {
            name: "",
            type: "bar",
            roundCap: true,
            barWidth: 10,
            showBackground: true,
            backgroundStyle: {
              color: "rgba(66, 66, 66, .3)",
            },
            data: [sr],
            coordinateSystem: "polar",
            itemStyle: {
              normal: {
                color: "#10c55b",
              },
            },
          },
          {
            name: "",
            type: "pie",
            startAngle: 80,
            radius: ["38%"],
            hoverAnimation: false,
            animation: false,
            center: ["50%", "50%"],
            itemStyle: {
              color: "rgba(255, 255, 255, 0)",
              borderWidth: 2,
              borderColor: "#10c55b",
            },
            data: [100],
          },
        ],
      });
      window.addEventListener("resize", function () {
        myPieChart.resize();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>