<template>
  <div class="quotaBalance-container">
    <div class="quotaBalance-box">
      <div style="margin-top:.2rem;">
        <el-button size="small" icon="el-icon-back" style="border:none" @click="handleBackHome">{{ $t("lang.BackHome") }}</el-button>
      </div>
      <div class="quotaBalance-wrapper">
        <div class="title">
          {{$t('lang.Quotabalance')}}
          <div class="line"></div>
        </div>
        <div class="charts-wrapper">
          <div class="storageCharts-wrapper">
            <pie-progress chartsName="storageCharts" :value="storagePer" ></pie-progress>
            <div style="color:#666">{{$t('lang.Storagebalance')}}</div>
            <div style="display:flex;justify-content: center;">
              <div>
                <div class="tips-item">
                  <div class="dot"></div>
                  <div>{{$t('lang.TotalStorage')}}<span style="margin:0 .1rem;color:#4b9eef">{{storage}}</span>MB</div>
                </div>
                <div class="tips-item">
                  <div class="dot" style="background-color: #E75A48;"></div>
                  <div>{{$t('lang.Used')}}<span style="margin:0 .1rem;color:#4b9eef">{{(storage - storageLeft).toFixed(2)}}</span>MB</div>
                </div>
                <div class="tips-item">
                  <div class="dot" style="background-color:#10c55b"></div>
                  <div>{{$t('lang.Left')}}<span style="margin:0 .1rem;color:#4b9eef">{{storageLeft}}</span>MB</div>
                </div>
              </div>
            </div>
          </div>
          <div class="storageCharts-wrapper">
            <pie-progress chartsName="flowCharts" :value="flowPer"></pie-progress>
            <div style="color:#666">{{$t('lang.Flowbalance')}}</div>
            <div style="display:flex;justify-content: center;">
              <div>
                <div class="tips-item">
                  <div class="dot"></div>
                  <div>{{$t('lang.TotalFLow')}}<span style="margin:0 .1rem;color:#4b9eef">{{flow}}</span>MB</div>
                </div>
                <div class="tips-item">
                  <div class="dot" style="background-color: #E75A48;"></div>
                  <div>{{$t('lang.Used')}}<span style="margin:0 .1rem;color:#4b9eef">{{(flow - flowLeft).toFixed(2)}}</span>MB</div>
                </div>
                <div class="tips-item">
                  <div class="dot" style="background-color:#10c55b"></div>
                  <div>{{$t('lang.Left')}}<span style="margin:0 .1rem;color:#4b9eef">{{flowLeft}}</span>MB</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
       <div class="quotaBalance-wrapper">
        <div class="title">
          {{$t('lang.detailedInformation')}}
          <div class="line"></div>
        </div>
        <div class="">
          <div v-for="item in balance" :key="item.id" class="detail-wrapper">
			<div class="time-title_a">
				<div class="time-title">{{$t("lang.Contractname")}}：{{ item.name }}</div>
				<div class="time-title">{{$t("lang.Servicedeadline")}}：{{ item.endTime }}</div>
			</div>
            <div class="surface">
              <pie-charts :chartsName="`pieChartsLeft + ${item.id}`" :chartsFlag="0" :value="item.storageLeft" :total="item.storage"></pie-charts>
              <pie-charts :chartsName="`pieChartsRight + ${item.id}`" :chartsFlag="1" :value="item.flowLeft" :total="item.flow"></pie-charts>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import * as Api from '@/api/apis';
import pieProgress from '@/components/echarts/pieProgress';
import pieCharts from '@/components/echarts/pie'
  export default {
    components:{
      pieProgress,
      pieCharts
    },
    data(){
      return{
        balance:[],
        flow:'',
        flowLeft:'',
        flowPer:0,
        storage:'',
        storageLeft:'',
        storagePer:0,
		timer:'',
      }
    },
	mounted() {
		this.getBalane()
		this.timer = setInterval(() => {
			this.getBalane()
		}, 5000);
	},
    methods:{
      handleBackHome(){
        this.$router.push('/nav/user/equipment')
      },
      getBalane(){
        let userid = JSON.parse(sessionStorage.getItem("user")).id;
        Api.deviceQuota({ id: userid }).then((res) => {
          if (res.data) {
            this.balance = res.data.billInfoList.filter(item => item.type === 2);
			console.log(this.balance)
            this.flow = res.data.flow;
            this.flowLeft = res.data.flowLeft;
            this.flowPer = res.data.flowPer;
            this.storage = res.data.storage;
            this.storageLeft = res.data.storageLeft;
            this.storagePer = res.data.storagePer;
          }
        });
      }
    },
	beforeDestroy() {
		if(this.timer){
			clearInterval(this.timer)
		}
	}
  }
</script>

<style lang="less" scoped>
  .quotaBalance-container{
    width: 100%;
    // height: 100%;
    background-color: #ddd;
    display: flex;
    justify-content: center;
    .quotaBalance-box{
      width: 70%;
      height: 100%;
      overflow: auto;
      background-color: #fff;
    }
    .quotaBalance-wrapper{
      padding: .4rem;
      .title{
        width: .8rem;
        font-size: .16rem;
         .line{
            width: 80%;
            height: 2px;
            background-color: #7bb3f2;
            margin-top: .05rem;
          }
      }

      .charts-wrapper{
        display: flex;
        justify-content: center;
        .storageCharts-wrapper{
          font-size: .16rem;
          text-align: center;
          .tips-item{
            display: flex;
            align-items: center;
            // justify-content: center;
            margin-top: .15rem;
            color: #666;
            .dot{
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background-color: #d7d7d7;
              margin-right: .1rem;
            }
          }
        }
        
      }
     
    }
  }
  .time-title{
    color: #b2b2b2;
    padding: .1rem .1rem;
  }
  .time-title_a{
	  background-color: #d7d7d7;
	  display: flex;
	  width: 100%;
	  flex-flow: row;
	  justify-content: space-between;
  }
  .surface{
    display: flex;
    justify-content: center;
    padding-top: .2rem;
  }
  .detail-wrapper{
    width: 70%;
    margin: 0 auto;
    text-align: center;
    margin-top: .3rem;
    border: 1px solid #ddd;
    padding-bottom: .2rem;
    font-size: .16rem;
  }
</style>